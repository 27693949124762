<template>
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3697 6.19752C11.3697 10.071 6.10575 13.3912 6.10575 13.3912C6.10575 13.3912 0.841797 10.071 0.841797 6.19752C0.841797 4.87668 1.39639 3.60994 2.38357 2.67596C3.37076 1.74199 4.70966 1.21729 6.10575 1.21729C7.50184 1.21729 8.84074 1.74199 9.82793 2.67596C10.8151 3.60994 11.3697 4.87668 11.3697 6.19752Z"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.10573 7.85776C7.07479 7.85776 7.86038 7.11451 7.86038 6.19768C7.86038 5.28084 7.07479 4.5376 6.10573 4.5376C5.13666 4.5376 4.35107 5.28084 4.35107 6.19768C4.35107 7.11451 5.13666 7.85776 6.10573 7.85776Z"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#999999',
    },
  },
};
</script>
